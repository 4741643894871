<template>
  <div class="address">
    <page-nav title="我的收货地址" />
    <div class="address-list">
      <van-swipe-cell v-for="item in list" :key="item.id">
        <div class="address-list-item">
          <div class="address-info">
            <div class="name">
              <span>{{item.name}}</span>
              <span>{{item.phone}}</span>
            </div>
            <div class="detail">
              <van-tag v-if="item.isDefault == 1" plain type="primary" color="red" class="default-tag">默认</van-tag>
              <span>{{item.provName}}{{item.cityName}}{{item.areaName}}{{item.addressDetail}}</span>
            </div>
          </div>
          <div class="edit">
            <van-button size="default" @click="onEdit(item)">编辑</van-button>
          </div>
        </div>
        <template #right>
          <van-button square text="删除" type="danger" class="cell-right-button" @click="onDelete(item)" />
        </template>
      </van-swipe-cell>
      
    </div>
    <div class="fix-bar">
      <div class="fix-cnt">
        <van-button type="info" round block @click="onAdd">新增收货地址</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Fetch from '../../common/fetch';
import { GetAddressList, DeleteAddress } from '../../common/interface';
import { Toast, SwipeCell, Tag, Dialog } from 'vant';
import PageNav from '@/components/PageNav.vue';
Vue.use(Toast);
Vue.use(SwipeCell);
Vue.use(Tag);
Vue.use(Dialog);

export default {
  name: 'Address',
  components: { PageNav },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      Fetch.post(GetAddressList).then(res => {
        this.list = res || [];
      }).catch(err => {
        Toast(err.msg || '请求失败');
      })
    },

    onEdit(item) {
      this.$router.push('/setting/address/edit?adid=' + item.id);
    },

    onDelete(item) {
      Dialog.confirm({
        title: '确认要删除此收货地址吗？',
        confirmButtonText: '删除',
      })
      .then(() => {
        const params = {
          id: item.id
        };
        Fetch.post(DeleteAddress, params).then(() => {
          this.fetchList();
        }).catch((err) => {
          Toast(err.msg || '请求失败');
        });
      }).catch(() => {
      });
    },

    onAdd() {
      this.$router.push('/setting/address/edit');
    },
  },
}
</script>

<style lang="scss" scoped>
.address {
  .fix-bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
    z-index: 120;
    background: #fff;
    .fix-cnt {
      padding: 10px 16px 8px;
    }
  }
}

.address-list {
  background: #fff;
  .address-list-item {
    position: relative;
    padding: 7px 50px 7px 10px;
    .address-info {
      overflow: hidden;
      font-size: 14px;
      color: #666;
      .name {
        color: #333;
        font-size: 16px;
        font-weight: bold;
        margin-top: 4px;
        span:first-child {
          margin-right: 5px;
        }
      }
      .detail {
        margin-top: 4px;
        .default-tag {
          margin-right: 8px;
        }
      }
    }
    .edit {
      position: absolute;
      width: 50px;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: red;
      button {
        color: red;
        font-size: 15px;
      }
    }
  }
}

.cell-right-button {
  height: 100%;
}
</style>